module.exports = {
    handleError: function(error, setMessage, setVisible, setColor) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 400) {
                setMessage(error.response.data);
                setVisible(true);
                setColor('danger')
                return
            }
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            setMessage('Server failed to process your request. Please, try again later.');
            setVisible(true);
            setColor('warning')
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return
        } else if (error.request) {
            console.log(error.request);
            setMessage('Sorry, something went wrong. Please, try again later.');
            setVisible(true);
            setColor('warning')
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
}