export const settingsValidation = (qboAccount, state) => {
    let result
    switch(true) {
        case !qboAccount:
            result = 'QBO Account required.'
            break
        case (state.integration_name !== 'FLOWHUB' && !state.pos_timezone):
            result = 'Time Zone field is required.'
            break
        case (!state.integration_name):
            result = 'Integration name is required.'
            break
        case (!state.parent_acc_name):
            result = 'Parent Bank Account Name is required.'
            break
        case (state.dutchie_key && state.dutchie_key.length !== 32):
            result = 'Check Dutchie key: should be 32 characters long'
            break
        case (state.canix_token && state.canix_token.length !== 64):
            result = 'Check Canix token: should be 64 characters long'
            break
        case ((state.flow_token && state.flow_token.length !== 36) || (state.flow_client_id && state.flow_client_id.length !== 36)):
            result = 'Check Flow token and ClientId: should be 34 characters long'
            break
        default:
            result = false
    }
    return result
}

export const checkWarnings = (state, setVisible, setMessage) => {
    if (state.warnings && state.warnings.length > 0) {
        let messageArr = []
        if (state.warnings.includes('tax_agent_exists')) {
            let agentMsg = "Activated Tax Agent detected in QBO file. This may cause services insode sales receipts to be created as taxable."
            messageArr.push(agentMsg)
        }
        if (state.warnings.includes('flowhub_permission_constraint')) {
            let fhMsg = "Additional Flowhub permissions required to support vendor billing. Refer to our manager for more details."
            messageArr.push(fhMsg)
        }
        console.log('WARNINGS', state.warnings)
        setMessage(messageArr)
        setVisible(true)
    }
}